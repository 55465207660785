<template>
  <div>
      <CCard class="col-md-12">
        <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">PO</span>STBACK LOGS</h4>
       </CRow>
      <CCardBody>
          <CRow class="col-md-12 col-12">
            <CCol class="col-md-4 col-12">
              <CRow>
              <label>Date Range: </label>
              </CRow>
              <CRow>
              <span class="form-control" @click="()=>date_modal=true" style="cursor: pointer;">
                {{ changeDateFormate(startDate)}}   <b>-</b>  {{ changeDateFormate(endDate) }}
              </span>
              <CModal
                title="Select Date Range"
                :show.sync="date_modal"
                :centered="true"
                scrollable
                color="dark"
                
              >
              <date-range-picker 
                :from="date_data.endDate"
                :to="date_data.startdate"
                :future="false"
                :panel="date_data.panel"
                :showControls="false" 
                @select="update"/>
              <template #footer>
                <CButton @click="date_modal = false" color="danger">Close</CButton>
              </template>
              </CModal>
            </CRow>
            </CCol>
           <CCol class="col-md-3 col-12 ml-md-3">
            <CRow class="">
              <label class="">Campaign</label>
            </CRow>
            <CRow class="">
              <multiselect
                class=""
                v-model="input.campid"
                placeholder="Campaign:"
                label="name"
                track-by="_id"
                :options="camp_list"
                :multiple="false"
                :taggable="false"
                :preselect-first="true"
                :close-on-select="true"
              >
              </multiselect>
            </CRow>
          </CCol>
              <!-- <date-time-picker
                class="ml-2 col-md-6 col-12"
                :endDate="endDate"
                :singleDate="false"
                :startDate="startDate"
                @onChange="dateFilter"
              /> -->
            <CCol class="col-md-4 col-12">
                <CRow class="col-md-12">&zwnj; </CRow>
                <CRow class="ml-4 mt-2"><CButton
                  class="ml-3"
                  color="success"
                  @click="FilterLogs()"
                  >Get Logs</CButton
                >
                <CButton
                  class="ml-3 mt-2 mt-md-0"
                  color="info"
                  @click="show_External_id_filter_modal = true"
                  >Search By External Id</CButton>
              </CRow>
            </CCol>
            </CRow>
      <div v-if="loading==true">Loading . . .</div>
        <div v-else>
        <CRow>
          <CCol>
          <CRow class="col-md-12 mt-3">
            <label>Total Rows : {{totalRows}}</label>
          </CRow>
        </CCol>
        <CCol>
          <div class="card-header-actions">
            <small class="text-muted"
              >
        <multiselect class="pb-2"
                size="sm"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect></small
            ></div></CCol>
            </CRow>
      <!-- Main table element -->
      <CDataTable
      :items="items"
      :dark="false"
      :items-per-page="tableperpage"
      :fields="fields"
      sorter
      hover
      striped
      border
      small
      fixed
      column-filter
      light
    >
    <template #updatedAt="{item}">
    <td class="cell">
      {{new Date(item.updatedAt).toLocaleDateString("en-US", {day: "numeric",year: "numeric",month: "long",hour: "numeric",minute: "numeric",hour12: true})}}
    </td></template>
    <template #_id="{item}">
    <td class="cell">
      {{item._id}}
    </td></template>
    <template #campid="{item}">
    <td class="cell">
      {{ allcampaign[item.campid]}}
    </td>
    </template>
    <template #conv_http_status_code="{item}">
    <td class="cell">
      <span v-if="item.conv_http_status_code">{{item.conv_http_status_code}}</span>
      <span v-else  class="link" @click="RetryPostback(item._id)">Retry</span>
    </td>
    </template>
    <template #post_conv_http_status_code="{item}">
    <td class="cell">
      {{item.post_conv_http_status_code}}
    </td>
    </template>
    <template #convUrl="{item}">
    <td class="cell">
      {{item.convUrl}}
    </td>
    </template>
    <template #conv_response="{item}">
    <td class="cell">
      {{item.conv_response}}
    </td>
    </template>
    <template #postConvUrl="{item}">
    <td class="cell">
      {{item.postConvUrl}}
    </td>
    </template>
    <template #post_conv_response="{item}">
    <td class="cell">
      {{item.post_conv_response}}
    </td>
    </template>
    <template #campid-filter="{item}">
        <input class="form-control form-control-sm" type="text" v-model="filter.campid" @keyup="customFilter('campid')">
    </template>
    </CDataTable>
    <div class="pagination">
    <a href="#" @click="getLogs('prev')">&laquo;</a>
    <a href="#" class="active">{{pageno}}</a>
    <a href="#" v-if="items.length > 0 " @click="getLogs('next')">&raquo;</a>
    </div>
  </div>
    </CCardBody></CCard>
   <!-- Filter By External Id Modal Start -->
    <CModal
      title="Postback logs by external id"
      :show.sync="show_External_id_filter_modal"
      size="xl"
      :centered="true"
      scrollable
      color="dark"
      hide-footer
    >
        <CRow>
          <CCol class="col-md-3 col-12 ml-md-3">
              <CInput class="col-md-12" v-model="input.external_id" placeholder="Enter External Id"></CInput>
          </CCol>
          <CCol class="col-md-3 col-12">
                <CButton
                  class="ml-3"
                  color="primary"
                  @click="getLogsByExternalId()"
                  >Get Logs</CButton
                >
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <div v-if="floading==true" class="text-center">Loading . . .</div>
            <div v-else>
            <CDataTable
              :items="filtered_item"
              :dark="false"
              :items-per-page="tableperpage"
              :fields="fields"
              sorter
              hover
              striped
              border
              small
              fixed
              light
            >
            
            </CDataTable>
            </div>
          </CCol>
        </CRow>
        <template #footer>
          <CRow class="mt-3">
          <CCol class="text-right">
              <CButton class="" @click="show_External_id_filter_modal=false" color="danger">Close</CButton>
          </CCol>
        </CRow>
        </template>
    </CModal>
     <!-- Filter By External Id Modal End -->
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DashboardService from "./../../services/dashboardController";
import SettingService from "./../../services/settingControlService";
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import CampaignControlService from "../../services/campaignControlService";
import Swal from "sweetalert2/dist/sweetalert2.js";
  export default {
    components: {Multiselect, DateTimePicker},
    data() {
      return {
        show_External_id_filter_modal: false,
        camp_list: [],
        date_data:{startdate:null,enddate:null,panel:null},
        date_modal:false,
        fields:[
            { key: "updatedAt", label: "Date" ,sortable: true},
            { key: "_id", label: "External Id",sortable: true },
            { key: "campid", label: "Campaign",sortable: true },
            { key: "convUrl", label: "Conversion Url",sortable: true },
            { key: "conv_http_status_code", label: "Conversion Status Code",sortable: true },
            { key: "conv_response", label: "Conversion Response" ,sortable: true},
            { key: "postConvUrl", label: "Post Conversion Url" ,sortable: true},
            { key: "post_conv_http_status_code", label: "Post Conversion Status Code",sortable: true },
            { key: "post_conv_response", label: "Post Conversion Response" ,sortable: true},    
        ],
        tableperpage:20,
        perPage:[20,50,100],
        pageno:1,
        items: [],
        totalRows: 1,
        currentPage: 1,
        filter: {},
        filterOn: [],
        startDate: new Date(),
        endDate: new Date(),
        input:{ edate: new Date(),
        sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        campid: null},
        allcampaign:[],
        useremail:"",
        loading:false,
        filtered_item:[],
        floading:false,
        all_data:[]
      }
    },
    computed: {
      
    },
   async mounted() {
    if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
    }
    else{
      if(localStorage.getItem("pblog_table") != null)
      this.tableperpage = parseInt(localStorage.getItem("pblog_table"));
      this.useremail = window.localStorage.getItem("email");
      this.dateFilter({startDate:new Date(this.startDate.setHours(0,0)),endDate:new Date(this.endDate.setHours(23,59))});
      this.getcampList();
      this.getLogs()
    }
      
    },
    methods: {
      customFilter(field) {
        let search = this.filter[field].toString().toLowerCase();
        let f_data = [];
        if(field == 'campid'){
          const campid = Object.keys(this.allcampaign).filter(key => this.allcampaign[key].toLowerCase().includes(search));
          f_data = this.all_data.filter(item => campid.some(campid => item.campid.includes(campid)));
        }
        else {
          f_data = this.all_data.filter(row =>
            row[field].toString().toLowerCase().includes(search)
          );
        }
        this.items = f_data;
      },
     async RetryPostback(id){
      let response = await SettingService.retryPostback(id);
      this.getLogs()
      if(response.message){
        Swal.fire({title: "Success!",text: response.message,icon: "success",position: 'top-end',timer: 1000});
      }
     },
       swap(json){
        var ret = {};
        for(var key in json){
          ret[json[key]] = key;
        }
        return ret;
      },
      async getcampList() {
      let res = await DashboardService.camplist({
          useremail: this.useremail,
        });
      this.allcampaign = this.swap(res.message.campaign)
      var response = await CampaignControlService.get();
       var campaign = response.message.campaigns;
       campaign.map((t) => {
        this.camp_list.push({
          _id: t._id,
          name: t.campname,
        });
      });
    },
      changeDateFormate(d){
      var p = new Date(d);
      return p.toLocaleDateString("en-US", {day: "numeric",year: "numeric",month: "long"})
    },
      update(values) {
      this.date_data.startdate = values.from
      this.date_data.enddate = values.to
      this.date_data.panel = values.panel
      this.dateFilter({startDate:new Date(values.from),endDate:new Date(values.to)})
      this.date_modal = false;
    },
      dateFilter(data) {
      this.input.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.input.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.startDate = data.startDate
      this.endDate = data.endDate
    },
      checkPage(){
          localStorage.setItem("pblog_table", this.tableperpage);
          this.getLogs()
        },
      FilterLogs(){
          this.pageno = 1
          this.getLogs()
        },
       async getLogs(txt){
        try {
          if(txt == 'next'){this.pageno ++}
          if(txt == 'prev'){
            if(this.pageno != 1){
            this.pageno --
            }
          }
          var payload = {pageSize: this.tableperpage, currentPage: this.pageno,filters:{sdate:this.input.sdate,edate:this.input.edate,campid:''}}
          if(this.input.campid != null){
             payload = {pageSize: this.tableperpage, currentPage: this.pageno,filters:{sdate:this.input.sdate,edate:this.input.edate,campid:this.input.campid._id}}
          }
          this.loading = true
          let response = await SettingService.getpblogs(payload)
          this.items = response.message
          this.all_data = response.message
          this.totalRows = this.items.length
          this.loading = false
        } catch(e){
            console.error("Error in login")
            console.log(e)
        }
        },
        async getLogsByExternalId(){
          this.floading = true
          if(this.input.external_id){
          var payload = {filters:{external_id:this.input.external_id}}
          let response = await SettingService.getpblogs(payload)
          if(response.result == true){
            this.filtered_item = response.message
          }else{
            this.filtered_item = []
            Swal.fire({title: "Error!",text: response.message,icon: "error",position: 'top-end',timer: 1000});
          }
          }
          else{
            this.filtered_item = []
            Swal.fire({
                  title: "Error!",
                  text: "Enter External Id",
                  icon: "error",
                  confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                });
          }
          this.floading = false
        }
    }
  }
</script>

<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 22px;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

.convurl{
  width: 28%;
}
.cell {
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}
.cell:hover{
  text-overflow: clip;
  overflow: unset;
  white-space: normal;
}
</style>
